<template>
  <div>
    <div v-bind:style="'height:'+height" class="main">
      <div class="inData"></div>
      <div class="block" v-for="(item,index) in data" :key="index" @click="openImage(item.Image)">
        <img :src="item.Image">
        <p class="title">奖品名称：<br>{{ item.Title }}</p>
        <p class="time">{{ item.UpTime ? item.UpTime : item.Time }}</p>
      </div>
    </div>

    <div class="findImage" v-if="imageUrl">
      <img :src="imageUrl" alt="" class="img" @click="closeImage">
    </div>
    <load ref="load"></load>
  </div>
</template>

<script>
import {initPrie} from "@/api/user";
import load from '../../../components/load'

export default {
  data() {
    return {
      height: '0px',
      data: [],
      imageUrl: ''
    }
  },
  components: {load},
  mounted() {

    let height = document.documentElement.clientHeight - 40 - 150 - 41 - 5 - 20
    this.height = height + 'px'

    let ActivityInfo = JSON.parse(localStorage.getItem("ActivityInfo"))
    let ActivityID = ActivityInfo.id
    let userInfo = JSON.parse(localStorage.getItem("user"));
    let UserID = userInfo.id;

    this.$refs.load.open()

    initPrie({ActivityID, UserID}).then(res => {
      this.$refs.load.close()
      let data = res.payload
      for (let i in data) {
        let Time = data[i].Time
        let day = new Date(Time);
        let timeHours = day.getHours()//时
        let timeMinutes = day.getMinutes()  //分
        let second = day.getSeconds()
        if (timeHours < 10) {
          timeHours = '0' + timeHours
        }
        if (timeMinutes < 10) {
          timeMinutes = '0' + timeMinutes
        }
        if (second < 10) {
          second = '0' + second
        }

        data[i].Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate() + ' ' + timeHours + ':' + timeMinutes + ':' + second;

        if (data[i].UpTime){
          let days = new Date(data[i].UpTime);

          let timeHours = days.getHours()//时
          let timeMinutes = days.getMinutes()  //分
          let second = days.getSeconds()
          if (timeHours < 10) {
            timeHours = '0' + timeHours
          }
          if (timeMinutes < 10) {
            timeMinutes = '0' + timeMinutes
          }
          if (second < 10) {
            second = '0' + second
          }

          data[i].UpTime = days.getFullYear() + '-' + (days.getMonth() + 1) + '-' + days.getDate() + ' ' + timeHours + ':' + timeMinutes + ':' + second;
        }
      }
      this.data = data
    })
  },
  methods: {
    openImage(Image) {
      this.imageUrl = Image
    },
    closeImage() {
      this.imageUrl = ''
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  overflow-y: auto;
  width: 100%;
}

.inData {
  width: 100%;
  height: 10px;
}

.block {
  background-color: whitesmoke;
  width: 90%;
  height: 130px;
  margin: 0 auto;
  position: relative;
  border-radius: 10px;
  margin-bottom: 20px;

  img {
    width: 80px;
    position: absolute;
    left: 10px;
    top: 10px;
  }

  .title {
    position: absolute;
    left: 100px;
    top: 10px;
    font-size: 12px;
  }

  .time {
    float: left;
    position: absolute;
    left: 100px;
    bottom: 10px;
    font-size: 12px;
  }
}

.findImage {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);

  .img {
    width: 280px;
    height: 560px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -140px;
    margin-top: -280px;
  }
}
</style>
