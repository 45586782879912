<template>
  <div>
    <div v-if="data.length>=1">
      <div class="data" v-bind:style="'height:'+height" @scroll="scrollEvent">
        <div class="block" v-for="(item,index) in data" :key="index" @click="openImg(item)">
          <div class="img"><img :src="item.ImageUrl"></div>
          <p class="SidNumber">编号：{{ item.WorkNumber }}</p>
          <p class="penName">标题：{{ item.PenName }}</p>
          <p class="Collect">人气值：{{ item.Collect }}</p>
          <p class="time">时间：{{ item.Time }}</p>
        </div>
      </div>
    </div>

    <div class="notdata" v-if="data.length==0&&start == '作品'"
         v-bind:style="'line-height:'+height+';height:'+(height*2)">暂无作品
    </div>
    <div class="notdata" v-if="data.length==0&&start == '点赞'" v-bind:style="'line-height:'+height">暂无点赞</div>
    <load ref="load"></load>
    <Details ref="Details"></Details>
  </div>
</template>

<script>
import {Notify, Toast} from "vant";
import {initcollect, initwork} from "@/api/user";
import load from '../../../components/load'
import Details from "../../../components/Details";
export default {
  props: {
    start: {
      type: String,
    },
  },
  components: {load,Details},
  data() {
    return {
      height: '0px',
      data: [],
      clientWidth: document.documentElement.clientWidth,
      skip: 1,
      pageTol: 0,
    }
  },
  mounted() {
    let height = document.documentElement.clientHeight - 40 - 150 - 41 - 5 - 15
    this.height = height + 'px'
  },
  methods: {
    // 打开详情
    openImg(item) {
      this.$refs.Details.openImg(item)
    },
    findwork(value) {
      if (value) {
        this.data = []
        this.skip = 1
      }

      let userInfo = JSON.parse(localStorage.getItem("user"))
      let UserID = userInfo.id

      let CompanyID = JSON.parse(localStorage.getItem("CompanyID"))

      let pageIndex = this.skip
      let pageSize = 10

      this.$refs.load.open()

      initwork({UserID, CompanyID, pageIndex, pageSize}).then(res => {

        this.$refs.load.close()

        if (res.errorCode == 200) {
          let payload = res.payload
          let data = payload.data

          for (let i in data) {
            let Time = data[i].Time
            let day = new Date(Time);
            data[i].Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()

            let penName = data[i].PenName
            if (penName.length >= 5) {
              data[i].PenName = penName.slice(0, 5) + '...'
            }
          }
          let number = payload.total
          number = Math.ceil(number / 10)
          this.pageTol = number
          for (let i in data) {
            this.data.push(data[i])
          }
        } else {
          Notify({type: 'warning', message: res.errorMsg});
        }
      })
    },
    //查询点赞
    findcollect(value) {
      if (value) {
        this.data = []
        this.skip = 1
      }

      this.$refs.load.open()
      let userInfo = JSON.parse(localStorage.getItem("user"))
      let UserID = userInfo.id
      let pageIndex = this.skip
      let pageSize = 10


      let CompanyID = JSON.parse(localStorage.getItem("CompanyID"))

      initcollect({UserID, CompanyID, pageIndex, pageSize}).then(res => {
        this.$refs.load.close()
        if (res.errorCode == 200) {
          let data = res.payload.data

          for (let i in data) {
            if (data[i]) {
              let Time = data[i].Time
              if (Time) {
                let day = new Date(Time);
                data[i].Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
              }

              let penName = data[i].PenName
              if (penName.length >= 5) {
                data[i].PenName = penName.slice(0, 5) + '...'
              }
            }
          }
          let number = res.payload.total
          number = Math.ceil(number / 10)
          this.pageTol = number
          for (let i in data) {
            if (data[i]) {
              this.data.push(data[i])
            }
          }
        } else {
          Notify({type: 'warning', message: res.errorMsg});
        }
      })
    },

    scrollEvent(e) {
      if (e.srcElement.scrollTop + e.srcElement.clientHeight == e.srcElement.scrollHeight) {
        if (this.start == '作品') {
          if (this.skip == this.pageTol) {
            Toast('已经到底了～');
            return
          }
          this.skip++
          this.findwork()
        }
        if (this.start == '点赞') {
          if (this.skip == this.pageTol) {
            Toast('已经到底了～');
            return
          }
          this.skip++
          this.findcollect()
        }

        if (this.start == '收到赞') {
          if (this.skip == this.pageTol) {
            Toast('已经到底了～');
            return
          }
        }
      }
    },
  }
}
</script>


<style scoped lang="scss">
.data {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  justify-content: space-around;
  background-color: #f0f0f0;

  .block {
    width: 46%;
    height: 334px;
    margin-bottom: 5px;
    box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
    background-color: #FFFFFF;
    position: relative;

    .img {
      position: absolute;
      width: 145px;
      height: 220px;
      border: 1px solid rgba(151, 55, 51, 0.2);
      left: 50%;
      margin-left: -72.5px;
      top: 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }


    p {
      text-indent: 1rem;
      font-size: 11px;
      color: #020202;
    }

    i {
      margin-right: 10px;
      display: inline-block;
      background-image: url("../../../assets/image/create/like.png");
      width: 15px;
      height: 15px;
      background-size: 100% 100%;
    }

    .SidNumber {
      position: absolute;
      left: 50%;
      margin-left: -88px;
      top: 230px;
    }

    .penName {
      position: absolute;
      left: 50%;
      margin-left: -88px;
      top: 251px;
    }

    .Collect {
      position: absolute;
      left: 50%;
      margin-left: -88px;
      top: 293px;
    }

    .time {
      position: absolute;
      left: 50%;
      margin-left: -88px;
      top: 272px;
    }

    .collect {
      position: absolute;
      left: 50%;
      margin-left: -88px;
      top: 298px;
    }
  }

  .blocks {
    width: 100%;
    height: 30px;
  }
}

.notdata {
  width: 100%;
  height: 100%;
  color: #898989;
  text-align: center;
  font-size: 14px;
}

.data::after {
  content: "";
  width: 46%;
}

</style>
