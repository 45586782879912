<template>
  <div class="user">
    <Header ref="Header"></Header>
    <div class="info">
      <div class="avatar" @click="openavatar">
        <img :src="userInfo.Avatar">
      </div>
      <div class="name">笔名：{{ userInfo.Name }}</div>
      <div class="id">账号：<span v-if="userInfo.Phone ">{{ userInfo.Phone }}</span></div>
      <div class="popularity">人气值：{{ Collect }}</div>
      <div class="quit" @click="quit">退出</div>
    </div>
    <div class="nav">
      <div class="block" v-bind:class="{ set: start=='作品' }" @click="openwork">我的作品</div>
      <div class="block" v-bind:class="{ set: start=='点赞' }" @click="openlike">我的点赞</div>
      <div class="block" v-bind:class="{ set: start=='我的奖品' }" @click="openPrize">我的奖品</div>
      <!--      <div class="block" v-bind:class="{ set: start=='评论' }" @click="opencomment">我的弹幕</div>-->
    </div>
    <div class="in"></div>
    <Avatar ref="Avatar"></Avatar>
    <Details ref="Details"></Details>
    <Prize ref="Prize" v-if="start=='我的奖品'"></Prize>
    <Work ref="Work" :start="start" v-if="start=='作品'||start=='点赞'"></Work>
    <load ref="load"></load>
  </div>
</template>

<script>
import Header from "../../components/Header";
import {Dialog, Notify} from 'vant'
import {
  putavatar, findPopularity
} from '../../api/user'
import load from '../../components/load.vue'
import Avatar from '../../components/Avatar'
import Details from '../../components/Details'
import Work from './com/Work.vue'
import Prize from './com/Prize.vue'


export default {
  data() {
    return {
      height: '0px',
      start: '作品',
      userInfo: {},
      Collect: 0
    }
  },
  components: {
    Header,
    Avatar,
    Details,
    Prize,
    Work,
    load
  },
  mounted() {
    this.init()
  },
  methods: {
    Popularity() {
      let userInfo = JSON.parse(localStorage.getItem("user"))
      let UserID = userInfo.id

      let CompanyID = JSON.parse(localStorage.getItem("CompanyID"))
      let ActivityInfo = JSON.parse(localStorage.getItem("ActivityInfo"))
      let ActivityID = ActivityInfo.id

      findPopularity({UserID, CompanyID, ActivityID}).then(res => {
        let payload = res.payload
        this.Collect = payload
      })
    },
    openavatar() {
      this.$refs.Avatar.openView()
    },
    selectImg(data) {
      let id = this.userInfo.id
      this.$refs.load.open()
      putavatar({data, id}).then(res => {
        if (res.errorCode == 200) {
          let payload = res.payload
          localStorage.setItem("user", JSON.stringify(payload))
          this.userInfo = payload
          this.$refs.load.close()
          Notify({type: 'success', message: '更改头像成功'});
          this.Popularity()
        } else {
          Notify({type: 'warning', message: res.errorMsg});
        }
      })
    },
    quit() {
      Dialog.confirm({
        message: '是否退出当前的账号？',
      }).then(() => {
        localStorage.removeItem('user')
        this.$router.push(
            {
              path: '/login'
            }
        )
      }).catch(() => {
      });
    },
    openwork() {
      if (this.start !== '作品') {
        this.start = '作品'
        setTimeout(() => {
          this.$refs.Work.findwork(1)
        })
      }
    },
    openlike() {
      if (this.start !== '点赞') {
        this.start = '点赞'
        setTimeout(() => {
          this.$refs.Work.findcollect(1)
        })

      }
    },
    openPrize() {
      if (this.start !== '我的奖品') {
        this.start = '我的奖品'
        this.data = []
        // this.findreceive()
      }
    },
    init() {
      let userInfo = JSON.parse(localStorage.getItem("user"))
      this.userInfo = userInfo
      this.$refs.Work.findwork()
      this.Popularity()
    }
  }
}
</script>

<style scoped lang="scss">
.user {
  .info {
    width: 100%;
    height: 150px;
    background-color: whitesmoke;
    position: relative;

    .avatar {
      position: absolute;
      width: 92px;
      height: 92px;
      background-color: #d8d8d8;
      border-radius: 50%;
      left: 40px;
      top: 30px;
      //background-image: url("../assets/image/login/avatar.png");
      //background-size: 100% 100%;

      .input-loc-img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .quit {
      color: #973733;
      position: absolute;
      right: 20px;
      bottom: 20px;
      font-size: 12px;

    }

    @mixin txt {
      position: absolute;
      left: 150px;
      font-size: 13px;
      color: #666666;
    }

    .name {
      @include txt;
      top: 25px;
    }

    .id {
      @include txt;
      top: 55px;
    }

    .grade {
      @include txt;
      top: 83px;
    }

    .popularity {
      @include txt;
      top: 85px;
    }


  }

  .in {
    width: 100%;
    height: 5px;
    background-color: #f0f0f0;
  }

  .nav {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-around;
    //border-bottom: 1px solid #efefef;
    .block {
      color: #020202;
      width: 80px;
      height: 40px;
      display: inline-block;
      line-height: 40px;
      font-size: 12px;
      text-align: center;
    }

    .set {
      border-bottom: 3px solid #973733;
    }
  }
}
</style>
`
