import request from "../utils/request";

//查询人气值
export const findPopularity = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/User/findPopularity",
        method: "get",
        params: query,
    });
};

//查询点赞数
export const initcollect = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/User/findCollect",
        method: "get",
        params: query,
    });
};

//查询作品
export const initwork = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/User/findWork",
        method: "get",
        params: query,
    });
};


//查询当前活动中奖信息
export const initPrie = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/User/findPrize",
        method: "get",
        params: query,
    });
};


//更新头像
export const putavatar = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/User/upavatar",
        method: "post",
        data: query,
    });
};

export const editName = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/User/editName",
        method: "post",
        data: query,
    });
}
